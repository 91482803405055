import { useEffect } from 'react'
import { useRouter } from 'next/router'
import React from 'react'

export default function Home() {
    const router = useRouter()

    useEffect(() => {
        router.push('/dashboard')
    })

    return <></>
}
